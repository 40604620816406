/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; // Ajustez la hauteur si nécessaire
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.container {
  max-width: 430px;
  width: 100vw; /* Adjusted */
  height: 100vh;
  background: url('bg.svg');
  background-size: cover;
  overflow-x: hidden;
  display: flex; /* Added */
  justify-content: center; /* Added */
  align-items: center; /* Added */

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    overflow-x: hidden;
  }

  h2 {
    color: #ffffff;
    text-align: center;
    font-family: Public Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  h3 {
    color: #ffffff;
    text-align: center;
    font-family: Public Sans, serif;
    font-weight: bolder;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
  }

  .avatar-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .list {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}
